
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import useRetailOverview from "@/hooks/claim/useRetailOverview";
import PayoutRoundDetail from "./PayoutRound/index.vue";
import UploadDetail from "./UploadDetail/index.vue";
import {
  deleteReimbursement,
  payoutRoundSubmit,
  payoutForm,
  exportFailResult,
} from "@/API/claim/claimRT";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import downloadFile from "@/utils/claim/downloadFile";

export default defineComponent({
  components: {
    PayoutRoundDetail,
    UploadDetail,
    Breadcrumb,
  },
  setup() {
    const isActive = ref(false); //勾选的Payout Round条目Status 是否为Controlling Confirmed

    const router = useRouter();
    // 主页面表格数据相关逻辑
    const {
      isMBLC,
      isMBAFC,
      payoutVal,
      statusVal,
      selectSize,
      payoutList,
      statusList,
      paginationObj,
      claimListParams,
      tableHeight,
      columns,
      claimListData,
      getTableData,
      pageChange,
      sizeChange,
      getTableDateHandle,
      getRoundListHandle,
    } = useRetailOverview();
    // 用户角色设置及判断
    const curRole = ref<string>("");
    // 设置isVisible状态
    const isVisible = ref<boolean>(false);
    const isUploadVisible = ref<boolean>(false);
    // 关闭抽屉
    const closeSub = (param: { isVisible: boolean }) => {
      isVisible.value = param.isVisible;
    };
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      isUploadVisible.value = param.isUploadVisible;
    };
    // 功能按钮区域
    // payout round
    const generateRoundHandle = () => {
      isVisible.value = true;
    };
    const uploadHandle = (role: string) => {
      curRole.value = role;
      isUploadVisible.value = true;
    };
    // 表格复选框逻辑
    const state = reactive<any>({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: [],
    });
    const start = () => {
      // ajax request after empty completing
      state.selectedRowKeys = [];
      state.selectedRows = [];
    };
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
      isActive.value = false;
      for (let i = 0; i < selectedRows.length; i++) {
        if ((selectedRows[i] as any).statusLabel === "Controlling Confirmed") {
          isActive.value = true;
        }
      }
    };
    // Submit for checking接口调用
    const submitCheckingParams = reactive({ ids: [] });
    const submitCheckingHandle = () => {
      const selectedArr: any = [];
      if (state.selectedRows.length < 1) {
        Modal.warning({
          title: "Tips",
          content: "Please select one and click again!",
          okText: "Confirm",
          centered: true,
        });
        return;
      }
      Modal.confirm({
        title: "Tips",
        content: "Do you want to submit selected payout round?",
        okText: "Confirm",
        centered: true,
        onOk() {
          for (let i = 0; i < state.selectedRows.length; i++) {
            selectedArr.push(state.selectedRows[i].id);
          }
          submitCheckingParams.ids = selectedArr;
          payoutRoundSubmit(submitCheckingParams).then((res) => {
            if (res.code === "0") {
              message.success(res.message);
              // 刷新页面
              getTableData();
            } else {
              Modal.error({
                title: "Tips",
                content: res.message,
                centered: true,
              });
            }
          });
        },
      });
    };
    // export Fail Result
    const exportFailResultHandle = () => {
      if (
        state.selectedRowKeys.length === 0 ||
        state.selectedRowKeys.length > 1
      ) {
        Modal.warning({
          title: "Tips",
          content: "Please select one and click again!",
          centered: true,
        });
      } else if (state.selectedRowKeys.length === 1) {
        const payoutRoundId = state.selectedRows[0].id;
        const params = {
          url: "/claimapi/payout/round/failResult",
          method: "post",
          params: { roundId: `${payoutRoundId}` },
          fileName: `${+new Date()}.xlsx`,
        };
        downloadFile(params, "application/vnd-excel; char-set=UTF-8");
      }
    };
    // payment入口连接处
    const payoutFromHandle = () => {
      if (!isActive.value) {
        return;
      }
      if (
        state.selectedRowKeys.length === 0 ||
        state.selectedRowKeys.length > 1
      ) {
        Modal.warning({
          title: "Tips",
          content: "Please select one and click again!",
          centered: true,
        });
      } else if (state.selectedRowKeys.length === 1) {
        if (state.selectedRows[0].statusLabel !== "Controlling Confirmed") {
          Modal.warning({
            title: "Tips",
            content:
              "Please select the data with the status of controlling confirmed!",
            centered: true,
          });
          return;
        }
        const payoutRoundId = state.selectedRows[0].id;
        payoutForm(payoutRoundId).then((res) => {
          if (res.code === "0") {
            message.success(`Generate Payout Form ${res.message}`);
            getTableData();
          } else if (res.code === "50065") {
            message.warn(res.message);
          } else {
            message.warn(`Generate Payout Form ${res.message}`);
          }
        });
      }
    };
    const viewHandle = (id: string) => {
      router.push({ path: "/claim/claimDetail", query: { id: id } });
    };
    // 删除数据接口调用
    const deleteHandle = (id: string) => {
      deleteReimbursement({ id: id }).then((res) => {
        if (res.code === "0") {
          message.success(res.message);
          getTableData();
          getRoundListHandle();
        } else {
          Modal.error({
            title: "Tips",
            content: res.message,
            centered: true,
          });
        }
      });
    };

    const refreshTable = () => {
      getTableData();
      getRoundListHandle();
    };

    onMounted(() => {
      getTableData();
    });

    return {
      isMBLC,
      isMBAFC,
      isActive,
      curRole,
      payoutVal,
      payoutList,
      statusList,
      statusVal,
      selectSize,
      generateRoundHandle,
      uploadHandle,
      submitCheckingHandle,
      exportFailResultHandle,
      getTableDateHandle,
      start,
      onSelectChange,
      payoutFromHandle,
      columns,
      claimListData,
      getTableData,
      viewHandle,
      deleteHandle,
      isVisible,
      isUploadVisible,
      closeSub,
      closeUpload,
      tableHeight,
      pageChange,
      sizeChange,
      paginationObj,
      claimListParams,
      refreshTable,
      ...toRefs(state),
    };
  },
});
