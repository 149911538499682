import { ref, reactive } from 'vue';
import * as API from '@/API/claim/claimRT';
import {
    ClaimListQueryParams,
    ClaimTableData,
    PayoutRoundListData,
    DocType,
} from '@/views/Claim/RT/types';
import usePage from '@/hooks/claim/usePage';
import { useStore } from 'vuex';
const useRetailOverview = () => {
    const isMBLC = ref(false);
    const isMBAFC = ref(false);

    const store = useStore();
    const entityCode = (store.state.user as any).legalEntity.entityCode;
    isMBLC.value = entityCode === 'MBLC' ? true : false;
    isMBAFC.value = entityCode === 'MBAFC' ? true : false;
    // 分页逻辑
    const { paginationObj, pageChange, sizeChange } = usePage();
    /* 查询下拉选择区域 start*/
    const payoutVal = ref('');
    const statusVal = ref('');
    const selectSize = ref<number>(20);
    //下拉列表数据
    const payoutList = ref<any[]>([]);
    const statusList = ref<DocType[]>([
        { label: 'All', value: '' },
        { label: 'Activated', value: 1 },
        { label: 'Submitted', value: 2 },
        { label: 'SICCO Checked', value: 3 },
        { label: 'SIPO Confirmed', value: 7 },
        { label: 'Spot Checked', value: 8 },
        { label: 'Controlling Confirmed', value: 9 },
        { label: 'Payment Initiated', value: 5 },
        { label: 'Closed', value: 6 },
    ]);
    const getRoundListHandle = () => {
        // 注：目前接口传参afcLcType默认AFC
        // const params = {'afcLcType': isMBAFC.value?'AFC':'LC', 'type': 'RT'}
        const params = { afcLcType: '', type: 'RT' };
        API.getRoundList(params).then((res) => {
            const allObj = { id: '', name: 'All' };
            res.unshift(allObj);
            payoutList.value = res;
        });
        // const array = ref<any[]>([]);
        // const params = {'afcLcType': 'LC', 'type': 'RT'}
        // API.getRoundList(params).then((res) => {
        //     // const allObj = {id: '', name: 'All'}
        //     // res.unshift(allObj)
        //     // payoutList.value= res
        //     array.value.push(...res);
        //     debugger
        //     API.getRoundList({'afcLcType': 'AFC', 'type': 'RT'}).then((res1) => {

        //         // payoutList.value= res
        //         array.value.push(...res1);
        //         const allObj = {id: '', name: 'All'}
        //         // res.unshift(allObj)
        //         array.value.unshift(allObj)
        //         payoutList.value= array.value;
        //     })
        // })
    };
    getRoundListHandle();
    /* 查询下拉选择区域 end*/
    // 分页
    /* 主页面列表数据表格 start */
    const claimListParams = reactive<ClaimListQueryParams>({
        // afcLcType: isMBAFC.value?'AFC':'LC',
        afcLcType: '',
        current: 0,
        size: 20,
        status: '',
        roundId: '',
        type: 'RT',
    });
    // 设置表格
    const tableHeight = window.innerHeight - 370;
    const columns = reactive([
        { title: 'F&L', dataIndex: 'afcLcType', width: 80 },
        {
            title: 'Payout Round',
            dataIndex: 'name',
            width: 120,
            slots: { customRender: 'round' },
        },
        { title: 'VIN Count', dataIndex: 'vinCount', width: 80 },
        {
            title: 'Latest Upload Time',
            dataIndex: 'lastUplpoadTime',
            width: 130,
        },
        { title: 'Status', dataIndex: 'statusLabel', width: 80 },
        {
            title: 'Operation',
            width: 120,
            slots: { customRender: 'operation' },
        },
    ]);
    const claimListData = ref<ClaimTableData[]>([]);
    // 主数据列表接口查询
    const getTableData = () => {
        API.getClaimOverview(claimListParams).then((res) => {
            claimListData.value = res.content;
            paginationObj.total = parseInt(res.totalElements);
        });
    };
    /* 主页面列表数据表格 end */
    const getTableDateHandle = (val: any, key: string) => {
        if (key === 'afcLcType') {
            claimListParams.roundId = val;
        } else if (key === 'status') {
            claimListParams.status = val;
        }
        getTableData();
    };
    return {
        payoutVal,
        statusVal,
        selectSize,
        payoutList,
        statusList,
        paginationObj,
        claimListParams,
        tableHeight,
        columns,
        claimListData,
        getTableData,
        pageChange,
        sizeChange,
        getTableDateHandle,
        getRoundListHandle,
        isMBLC,
        isMBAFC,
    };
};
export default useRetailOverview;
