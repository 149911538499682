
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons-vue";
import { getUploadRoundList } from "@/API/claim/claimRT";
import { PayoutRoundListData } from "@/views/Claim/RT/types";
import { FileItem } from "@/API/types";
import downloadFile from "@/utils/claim/downloadFile";
import downloadFileUrl from "@/utils/claim/downloadFileUrl";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
export default defineComponent({
  components: {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  },
  emits: ["close-upload-visible", "change-table"],
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    curRole: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, context) {
    const isShowErrorTips = ref<boolean>(false);
    const isBeforeUpload = ref<boolean>(true);
    // 进度条
    const isShowUploadBtn = ref<boolean>(true);
    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0);
    const uploadFileSize = ref<string>("");
    const completeTips = ref<boolean>(false);
    // 上传功能出错后下载模态窗口
    const loading = ref<boolean>(false);
    const downloadModalVisible = ref<boolean>(false);
    const downloadTipsText = ref<string>("");
    const showDownload = ref(false);
    const roundVal = ref();
    // 导入控制按钮
    const isImport = ref<boolean>(false);
    // 文件上传
    const isClickAbled = ref<boolean>(true);
    const uploadComplete = ref<boolean>(true);
    const formData = new FormData();
    const emptyVisible = ref<boolean>(false);
    const emptyTipsText = ref<string>("");
    // 获取标题内容
    const title = computed(
      () => `Upload ${(props as any).curRole} Reimbursement List`
    );
    // // 设置抽屉显示状态
    const modal = (props as any).isUploadVisible;
    const roleVal = (props as any).curRole;
    const close = () => {
      context.emit("close-upload-visible", { isUploadVisible: false });
    };
    const roundList = ref<PayoutRoundListData[]>([]);
    // 获取upload round 列表
    const uploadListParams = { afcLcType: `${roleVal}`, type: "RT" };

    const params = uploadListParams;
    getUploadRoundList({ params }).then((res) => {
      roundList.value = res;
    });
    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("The file size should not be larger than 10MB!");
      }
      return isXlsx && isLt5M;
    };
    const uploadRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      isBeforeUpload.value = false;
    };

    const isShowTable = ref<boolean>(false);
    // 上传文件
    const uploadFile = (source: any) => {
      if (roundVal.value === undefined) {
        isShowErrorTips.value = true;
      } else if (roundVal.value?.trim() === "") {
        isShowErrorTips.value = true;
      } else {
        isShowErrorTips.value = false;
      }
      if (isShowErrorTips.value) return;
      isClickAbled.value = false;
      const params = {
        payoutRoundId: roundVal.value,
        file: formData,
      };
      axios({
        method: "post",
        data: formData,
        url: "/claimapi/payout/reimbursement/uploadReimbursement",
        params: params,
      }).then((res) => {
        console.log(res);
        isShowUploadBtn.value = false;
        curPercent.value = 100;
        completeTips.value = true;
        isClickAbled.value = true;
        if (res.data.code !== "0" && res.data.code !== "50061") {
          if (res.data.data) {
            showDownload.value = true;
          } else {
            showDownload.value = false;
          }
          // 显示模态窗口
          downloadModalVisible.value = true;
          downloadTipsText.value = res.data.message;
          // 存data里的信息
          if (res.data.data !== "")
            window.sessionStorage.setItem("errorFileName", res.data.data);
        } else if (res.data.code === "50061") {
          emptyVisible.value = true;
          emptyTipsText.value = res.data.message;
        } else {
          message.success(res.data.message);
          if (res.data !== "") {
            isShowTable.value = true;
            dataSource.value = res.data.data;
          } else {
            isShowTable.value = false;
          }
          isImport.value = true;
        }
      });
    };
    // 上传功能出错后下载模态窗口
    const handleDownload = () => {
      loading.value = true;
      // 调下载错误文件接口
      const path = window.sessionStorage.getItem("errorFileName")
        ? window.sessionStorage.getItem("errorFileName")
        : null;
      if (!path) return;
      const downloadParams = {
        payoutRoundId: roundVal.value,
        path: window.sessionStorage.getItem("errorFileName"),
      };
      const params = {
        url: `/claimapi/common/download/errorFile`,
        method: "get",
        params: downloadParams,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFileUrl(params, "application/vnd-excel; char-set=UTF-8").then(
        (res) => {
          loading.value = false;
          downloadModalVisible.value = false;
          emptyVisible.value = false;
        }
      );
    };
    const handleCancel = () => {
      downloadModalVisible.value = false;
    };
    const showUploadHandle = () => {
      isBeforeUpload.value = true;
      isShowUploadBtn.value = true;
      curPercent.value = 0;
      completeTips.value = false;
      window.sessionStorage.removeItem("errorFileName");
      isImport.value = false;
    };

    // 下载模板接口调用
    const downloadTemHandle = () => {
      const params = {
        url: `/claimapi/payout/reimbursement/templateDownload`,
        method: "get",
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };
    // 导入接口调用
    const importFile = () => {
      const params = {
        payoutRoundId: roundVal.value,
        file: formData,
      };
      axios({
        method: "post",
        data: formData,
        url: "/claimapi/payout/reimbursement/import",
        params: params,
      }).then((res) => {
        if (res.data.code !== "0") {
          Modal.warning({
            title: "Tips",
            content: res.data.message,
            centered: true,
          });
        } else {
          message.success(res.data.message);
          context.emit("change-table");
        }
      });
    };
    const dataSource = ref<any>([]);
    const columns = [
      {
        title: "车辆信息",
        dataIndex: "calculatorKey",
        key: "calculatorKey",
        width: 120,
      },
      {
        title: "状态",
        dataIndex: "msg",
        key: "msg",
        width: 80,
      },
    ];

    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );

    const handleExport = () => {
      const params = {
        url: `/claimapi/payout/reimbursement/exportWithoutCalculator`,
        method: "post",
        params: { messages: dataSource.value },
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    return {
      showDownload,
      isShowErrorTips,
      downloadModalVisible,
      downloadTipsText,
      handleDownload,
      handleCancel,
      roundVal,
      title,
      modal,
      roleVal,
      close,
      roundList,
      isShowTable,
      uploadFile,
      beforeUpload,
      uploadListParams,
      downloadTemHandle,
      uploadComplete,
      importFile,
      isBeforeUpload,
      isShowUploadBtn,
      uploadRequest,
      curPercent,
      curUploadFile,
      uploadFileSize,
      isImport,
      showUploadHandle,
      completeTips,
      isClickAbled,
      emptyVisible,
      emptyTipsText,
      dataSource,
      columns,
      spinWrapper,
      handleExport,
    };
  },
});
