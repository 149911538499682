import { fetchGet, fetchPostPic, fetchGetMess } from '../index';

/* claim主页面接口-2226 start */
// 查询条件payout round下拉列表数据接口
export const getRoundList = ( params = {afcLcType: '', type: ''}): Promise<any> => {
	return fetchGet('/claimapi/payout/round/roundList?afcLcType=' + params.afcLcType + '&type=' + params.type)
}
// 查询主数据接口
export const getClaimOverview = (params = {afcLcType: '', status: '', roundId: '', current: 0, size: 1, type: ''}): Promise<any> => {
	return fetchGet('claimapi/payout/round/listPage?' + 'afcLcType=' + params.afcLcType + '&status=' + params.status + '&roundId=' + params.roundId +  '&current=' + params.current + '&size=' + params.size + '&type=' + params.type)
}
// 删除round导入excel接口
export const deleteReimbursement = (params = {id: ''}): Promise<any> => {
	return fetchGetMess('/claimapi/payout/round/delReimbursement?id=' + params.id)
}
// payout round submit接口
export const payoutRoundSubmit = (data = {}): Promise<any> => {
	return fetchPostPic('/claimapi/payout/round/submit', data)
}
// export 接口
export const exportFailResult = (params = {}): Promise<any> => {
	return fetchPostPic('claimapi/payout/round/failResult', params)
}
/* claim主页面接口 end */

/* payout_round页面接口 start */
// payout_round列表接口
export const getPayoutRoundTable = (params = {}): Promise<any> => {
	return fetchGet('/claimapi/payout/round/list', params)
}
// payout_round generate接口
export const payoutRoundGenerate = (params = {}): Promise<any> => {
	return fetchPostPic('/claimapi/payout/round/generate', params)
}
// 删除round接口-2225
export const deletePayoutRound = (params = {id: ''}): Promise<any> => {
	return fetchGetMess('/claimapi/payout/round/delRound?id=' + params.id)
}
/* payout_round页面接口 end */
/* claim detail view 页面 接口 start */
// view页面统计接口
export const claimViewDetail = (params = {payoutRoundId: ''}): Promise<any> => {
	return fetchGet('/claimapi/payout/round/view/count?payoutRoundId=' + params.payoutRoundId)
}
// view页面列表接口
export const claimViewList = (params = {}): Promise<any> => {
	return fetchGet('/claimapi/payout/round/view/page', params)
}
// view页面export接口
export const viewExport = (params = {payoutRoundId: '', status: '', finalResult: '', vin: ''}): Promise<any> => {
	return fetchGet('claimapi/payout/round/view/export?payoutRoundId=' + params.payoutRoundId + '&status=' + params.status + '&finalResult=' + params.finalResult + '&vin=' + params.vin)
}
/* claim detail view 页面 接口 end */
/* 上传页面 start */
// 获取上传列表
export const getUploadRoundList = ( params = {}): Promise<any> => {
	return fetchGet('/claimapi/payout/round/uploadPayoutRoundList', params)
}
/* 上传页面 end */
// Generate Payout Form
export const payoutForm = (payoutRoundId: string): Promise<any> => {
	return fetchPostPic(`/claimapi/payout/round/generatePayoutForm/${payoutRoundId}`)
}